<template>
    <div>
        <vue-nestable :max-depth="1" v-model="nestableItems">
            <vue-nestable-handle
                slot-scope="{ item, index }"
                :item="item" :index="index">
                <div class="row">
                    <div class="col-6"><i class="ri-line-height"></i> {{item.text}}</div>
                    <div class="col-6">Sıra: {{index+1}}</div>
                </div>
            </vue-nestable-handle>
        </vue-nestable>
        <div class="row mt-4">
            <div class="col-12">
                <b-button @click="sendForm" variant="primary">{{$t('save')}}</b-button>
            </div>
        </div>

    </div>
</template>
<script>
import { VueNestable, VueNestableHandle } from 'vue-nestable'
import '@/assets/css/nestable/vue-nestable.css'
import PlacementExamClassroomsService from "@/services/PlacementExamClassroomsService";

export default {
    components: {
        VueNestable,
        VueNestableHandle
    },

    props: {
        classes:{
            type: Array,
            default: null
        },
        placement_exam_id: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            nestableItems: [

            ]
        }
    },
    metaInfo() {
        return {
            title: this.$t("placement_exam_classrooms_title")
        }
    },
    methods: {
        getClasses(){
            this.nestableItems = []
            this.classes.forEach((value) => {
                this.nestableItems.push({
                    id: value.id,
                    text: value.classroom_name,
                    classroom_id: value.classroom_id,
                    nationality_option: value.nationality_option,
                    rank: value.rank,
                    capacity:value.capacity
                })
            })
        },

        sendForm(){
            if(this.checkPermission('placementexamclassroom_update')){
                if(this.nestableItems.length > 0){
                    this.nestableItems.forEach((item, rank) => {
                        let formData = {
                            placement_exam_id: this.placement_exam_id,
                            classroom_id: item.classroom_id,
                            nationality_option: item.nationality_option,
                            rank: rank+1,
                            capacity: item.capacity
                        }

                        PlacementExamClassroomsService.update(item.id, formData)
                                                      .then(() => {
                                                      })
                    })
                    this.$emit('hide')
                    this.$toast.success(this.$t('placement_exam_classrooms_ranking_success'));
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        }

    },

    created() {

    },


    watch: {
        classes: {
            handler(val){
                this.getClasses()
            }
        }
    }
};
</script>
